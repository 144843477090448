import request from '@/utils/request'

export default {
  getList(params) {
    return request({
      url: '/v1/applyList',
      method: 'get',
      params
    })
  },
  getOne(id) {
    return request({
      url: `/v1/apply/${id}`,
      method: 'get'
    })
  },
  update(data) {
    return request({
      url: '/v1/applyUpdate',
      method: 'post',
      data
    })
  },
  create(data) {
    return request({
      url: '/v1/applyAdd',
      method: 'post',
      data
    })
  },
  delete(id) {
    return request({
      url: `/v1/applyDel/${id}`,
      method: 'get'
    })
  }
}
