<template>
  <div class="all-list">
    <van-nav-bar title="历史报修" left-text="返回" fixed left-arrow @click-left="goBack" @click-right="addDetail">
      <template #right>
        <van-icon name="plus" size="18" />
      </template>
    </van-nav-bar>
    <van-search class="search" placeholder="请输入搜索关键词" @search="onSearch" />
    <van-tabs v-model:active="activeName" class="list-tab" :ellipsis='false' @click="handleClick">
      <van-tab name="99" :title="'全部' + '(' + total + ')' "></van-tab>
      <van-tab name="1" :title="'待维修' + '(' + todo + ')'"></van-tab>
      <van-tab name="2" :title="'完成' + '(' + done + ')'"></van-tab>
      <van-tab name="10" :title="'在执行' + '(' + doing + ')'"></van-tab>
      <van-tab name="6" :title="'待确认' + '(' + to_done + ')'"></van-tab>
    </van-tabs>
    <van-pull-refresh v-model="isRefresh" head-height="80" distance="80"  @refresh="onRefresh">
      <van-list class="index-listPlus" v-model="loading" :finished="isFinished" :finished-text="finishedText" @load="loadMore">
      <van-cell class="bq-list" v-for="item in list" :key="item.index" @click="goDetail(item.id)">
        <van-swipe-cell>
          <van-row type="flex" class="tag">
            <van-col>
              <van-row type="flex">
                <van-col>
                  <div>
                    <div class="item-title">
                      <van-tag v-if="item.status && item.status !== '完成' && item.status !== '维修中'" type="primary">{{ item.status_excel }}</van-tag>
                      <van-tag v-if="item.status === '完成'" type="success">{{ item.status_excel }}</van-tag>
                      <van-tag v-if="item.status === '维修中'" type="warning">{{ item.status_excel }}</van-tag>
                      {{ item.equipment }} --{{ item.coding}}
                    </div>
                  </div>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
          <div style="padding: 8px">
            <van-row type="flex" class="details">
              <van-col>{{ item.member_list}}</van-col>
            </van-row>
            <van-row type="flex" class="details flex">
              <van-col>{{ item.arrange_time}}</van-col>
            </van-row>
            <van-divider />
            <div style="padding: 8px">
              <van-row type="flex" class="details">
                <van-col>是否停机：{{ item.halt}}</van-col>
              </van-row>
              <van-row type="flex" class="details">
                <van-col>报修时间：{{ item.create_time }}</van-col>
              </van-row>
              <van-row type="flex" class="details">
                <van-col v-if="item.complete_time">完成时间：{{ item.complete_time }}</van-col>
                <van-col v-else>完成时间：维修未完成</van-col>
              </van-row>
            </div>
          </div>
        </van-swipe-cell>
      </van-cell>
    </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import API from '@/api/project/apply'
export default {
  name: "projectPage",
  inject: ['goUrl', 'reload'],

  data() {
    return {
      activeName: '99',
      list: [],
      value:'',
      loading: false,
      isFinished: false,
      isRefresh: false,
      finishedText:'加载完成',
      isLoading: false,
      total: 0,
      todo: 0,
      done: 0,
      doing: 0,
      to_done: 0,
      countAlready: false,
      query: {
        page: 1,
        limit: 5,
        status: null
      }
    }
  },
  created() {
  },
  methods: {
    index() {
      this.isLoading = true
      if (this.isRefresh) {
        this.list = []
        this.query.page = 0
        this.isRefresh = false
        this.isFinished = false
      }
      this.toTop()
      API.getList(this.query).then(res => {
        this.list.push(...res.data.list.data)
        console.log(this.list.length, res.data.list.total)
        if (this.list.length >= res.data.list.total) {
          this.isFinished = true
        }
        if(!this.countAlready){
          this.total = res.data.total
          this.todo = res.data.todo
          this.done = res.data.done
          this.doing = res.data.doing
          this.to_done = res.data.to_done
          this.countAlready = true
        }
      })
      this.loading = false
      this.isLoading = false
    },
    loadMore() {
      if(!this.isLoading){
        this.query.page += 1
        this.index()
      }
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    handleClick(name) {
      if(name === '99'){
        this.query.status = null
        this.list = []
        this.query.page = 0
        this.index()
      } else {
        this.query.status = parseInt(name)
        this.list = []
        this.query.page = 0
        this.index()
      }
    },
    goBack() {
      this.goUrl('/index')
    },
    onSearch() {
      this.query.page = 0
      this.list = []
      this.index()
    },
    goDetail(id){
      const url = `/project/apply/edit/${id}`
      this.goUrl(url)
    },
    addDetail() {
      this.goUrl(`/project/apply/add`)
    },
    onRefresh() {
      this.reload()
      this.index()
    }
  },
}
</script>

<style scoped>
  .flex{
    display: flex;
    justify-content: space-between;
  }
</style>
